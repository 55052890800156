export const setAuthUser = (user) => {
    setAuthToken(user.token);
    localStorage.setItem('user', JSON.stringify(user));
    return true
}

export const removeAuthUser = () => {
    console.log('removeAuthUser::');
    removeAuthToken();
    return localStorage.removeItem('user');
}

export const getAuthUser = () => {
    return JSON.parse(localStorage.getItem('user'));
}

export const setAuthToken = (token) => {
    return localStorage.setItem('token', token)
}

export const getAuthToken = () => {
    const token = localStorage.getItem('token');
    return token ? token : '';
}

export const removeAuthToken = () => {
    return localStorage.removeItem('token');
}

export const setLang = async (value) => {
    return localStorage.setItem('lang', value);
}

export const getLang = () => {
    const lang = localStorage.getItem('lang');
    return lang ? lang : '';
}

export const removeLang = () => {
    localStorage.removeItem('lang');
}

export const setforgotPassword = (obj) => {
    return localStorage.setItem('forgotPasswordObject', JSON.stringify(obj));
};

export const getForgotPassword = () => {
    const forgotObj = localStorage.getItem('forgotPasswordObject');
    return forgotObj ? forgotObj : false
}