export const BASE_PATH = '/';

export const PRE_LOGIN_PATH = `${BASE_PATH}`;
export const POST_LOGIN_PATH = `${BASE_PATH}`;

export const LOGIN_PATH = `${BASE_PATH}login`;
export const FORGOT_PATH = `${BASE_PATH}forgot-password`;
export const CHANGE_FORGOT_PATH = `${BASE_PATH}reset-password`;

export const DASH_PATH = `${BASE_PATH}dashboard`;

export const USER_PATH = `${BASE_PATH}users`;
export const USER_ADD_PATH = `${BASE_PATH}users/add`;

export const PROFILE_PATH = `${BASE_PATH}profile`;
export const SETTINGS_PATH = `${BASE_PATH}settings`;

export const PRODUCTS_PATH = `${BASE_PATH}products`;
export const ADD_PRODUCTS_PATH = `${BASE_PATH}products/add`;

export const CMS_PATH = `${BASE_PATH}contentmanagementsystem`;
export const ADD_CMS_PATH = `${BASE_PATH}contentmanagementsystem/add`;

export const CATEGORIES_PATH = `${BASE_PATH}categories`;
export const ADD_CATEGORIES_PATH = `${BASE_PATH}categories/add`;
export const EDIT_CATEGORY_PATH = `${BASE_PATH}categories/edit`;
export const VIEW_CATEGORY_PATH = `${BASE_PATH}categories/view`;
export const EDIT_CATEGORY_BY_ID_PATH = `${BASE_PATH}${EDIT_CATEGORY_PATH}/:category_id`;
export const VIEW_CATEGORY_BY_ID_PATH = `${BASE_PATH}${VIEW_CATEGORY_PATH}/:category_id`;

export const SUB_CATEGORIES_PATH = `${BASE_PATH}sub-categories`;
export const ADD_SUBCATEGORIES_PATH = `${BASE_PATH}sub-categories/add`;
export const EDIT_SUBCATEGORIES_PATH = `${BASE_PATH}sub-categories/edit`;
export const VIEW_SUBCATEGORIES_PATH = `${BASE_PATH}sub-categories/view`;
export const EDIT_SUBCATEGORIES_BY_ID_PATH = `${BASE_PATH}${EDIT_SUBCATEGORIES_PATH}/:category_id`;
export const VIEW_SUBCATEGORIES_BY_ID_PATH = `${BASE_PATH}${VIEW_SUBCATEGORIES_PATH}/:category_id`;

export const END_CATEGORIES_PATH = `${BASE_PATH}endcategory`;
export const ADD_END_CATEGORY_PATH = `${BASE_PATH}endcategory/add`;
export const EDIT_END_CATEGORY_PATH = `${BASE_PATH}endcategory/edit`;
export const VIEW_END_CATEGORY_PATH = `${BASE_PATH}endcategory/view`;
export const EDIT_END_CATEGORY_BY_ID_PATH = `${BASE_PATH}${EDIT_END_CATEGORY_PATH}/:category_id`;
export const VIEW_END_CATEGORY_BY_ID_PATH = `${BASE_PATH}${VIEW_END_CATEGORY_PATH}/:category_id`;

export const CITY_PATH = `${BASE_PATH}cities`;
export const ADD_CITY_PATH = `${BASE_PATH}cities/add`;
export const VIEW_CITY_PATH = `${BASE_PATH}cities/view`;
export const VIEW_CITY_BY_ID_PATH = `${BASE_PATH}${VIEW_CITY_PATH}/:city_id`;
export const EDIT_CITY_PATH = `${BASE_PATH}city/edit`
export const EDIT_CITY_BY_ID_PATH = `${BASE_PATH}${EDIT_CITY_PATH}/:city_id`

export const HSN_PATH = `${BASE_PATH}hsn`;
export const ADD_HSN_PATH = `${BASE_PATH}hsn/add`;

export const MANUFACTURE_PATH = `${BASE_PATH}manufacturer`;
export const ADD_MANUFACTURE_PATH = `${BASE_PATH}manufacturer/add`;
export const VIEW_MANUFACTURE_PATH = `${BASE_PATH}manufacturer/view`
export const VIEW_MANUFACTURE_BY_ID_PATH = `${BASE_PATH}${VIEW_MANUFACTURE_PATH}/:manufacturer_id`
export const EDIT_MANUFACTURE_PATH = `${BASE_PATH}manufacturer/edit`
export const EDIT_MANUFACTURE_BY_ID_PATH = `${BASE_PATH}${EDIT_MANUFACTURE_PATH}/:manufacturer_id`

export const DESIGNATION_PATH = `${BASE_PATH}designation`;
export const ADD_DESIGNATION_PATH = `${BASE_PATH}designation/add`;
export const VIEW_DESIGNATION_PATH = `${BASE_PATH}designation/view`;
export const VIEW_DESIGNATION_BY_ID_PATH = `${BASE_PATH}${VIEW_DESIGNATION_PATH}/:designation_id`;
export const EDIT_DESIGNATION_PATH = `${BASE_PATH}designation/edit`;
export const EDIT_DESIGNATION_BY_ID_PATH = `${BASE_PATH}${EDIT_DESIGNATION_PATH}/:designation_id`;

export const ROLE_PATH = `${BASE_PATH}role`;
export const ADD_ROLE_PATH = `${BASE_PATH}role/add`;
export const VIEW_ROLE_PATH = `${BASE_PATH}role/view`;
export const VIEW_ROLE_BY_ID_PATH = `${BASE_PATH}${VIEW_ROLE_PATH}/:role_id`;
export const EDIT_ROLE_PATH = `${BASE_PATH}role/edit`;
export const EDIT_ROLE_BY_ID_PATH = `${BASE_PATH}${EDIT_ROLE_PATH}/:role_id`;

export const ROLE_SCREEN_PATH = `${BASE_PATH}role-screen`;
export const ADD_ROLE_SCREEN_PATH = `${BASE_PATH}role-screen/add`;
export const VIEW_ROLE_SCREEN_PATH = `${BASE_PATH}role-screen/view`;
export const VIEW_ROLE_SCREEN_BY_ID_PATH = `${BASE_PATH}${VIEW_ROLE_SCREEN_PATH}/:rolescreen_id`;
export const EDIT_ROLE_SCREEN_PATH = `${BASE_PATH}role-screen/edit`;
export const EDIT_ROLE_SCREEN_BY_ID_PATH = `${BASE_PATH}${EDIT_ROLE_SCREEN_PATH}/:rolescreen_id`;


export const DEPARTMENT_PATH = `${BASE_PATH}department`;
export const ADD_DEPARTMENT_PATH = `${BASE_PATH}department/add`;
export const VIEW_DEPARTMENT_PATH = `${BASE_PATH}department/view`;
export const VIEW_DEPARTMENT_BY_ID_PATH = `${BASE_PATH}${VIEW_DEPARTMENT_PATH}/:department_id`;
export const EDIT_DEPARTMENT_PATH = `${BASE_PATH}department/edit`;
export const EDIT_DEPARTMENT_BY_ID_PATH = `${BASE_PATH}${EDIT_DEPARTMENT_PATH}/:department_id`;


export const ATTRIBUTE_PATH = `${BASE_PATH}attribute`;
export const ADD_ATTRIBUTE_PATH = `${BASE_PATH}attribute/add`;
export const VIEW_ATTRIBUTE_PATH = `${BASE_PATH}attribute/view`;
export const VIEW_ATTRIBUTE_BY_ID_PATH = `${BASE_PATH}${VIEW_ATTRIBUTE_PATH}/:attribute_id`;
export const EDIT_ATTRIBUTE_PATH = `${BASE_PATH}attribute/edit`;
export const EDIT_ATTRIBUTE_BY_ID_PATH = `${BASE_PATH}${EDIT_ATTRIBUTE_PATH}/:attribute_id`;

export const SEGMENT_PATH = `${BASE_PATH}segment`;
export const ADD_SEGMENT_PATH = `${BASE_PATH}segment/add`;
export const VIEW_SEGMENT_PATH = `${BASE_PATH}segment/view`;
export const VIEW_SEGMENT_BY_ID_PATH = `${BASE_PATH}${VIEW_SEGMENT_PATH}/:segment_id`;
export const EDIT_SEGMENT_PATH = `${BASE_PATH}segment/edit`;
export const EDIT_SEGMENT_BY_ID_PATH = `${BASE_PATH}${EDIT_SEGMENT_PATH}/:segment_id`;

export const VARIANT_PATH = `${BASE_PATH}variant`;
export const ADD_VARIANT_PATH = `${BASE_PATH}variant/add`;
export const VIEW_VARIANT_PATH = `${BASE_PATH}variant/view`;
export const VIEW_VARIANT_BY_ID_PATH = `${BASE_PATH}${VIEW_VARIANT_PATH}/:variant_id`;
export const EDIT_VARIANT_PATH = `${BASE_PATH}variant/edit`;
export const EDIT_VARIANT_BY_ID_PATH = `${BASE_PATH}${EDIT_VARIANT_PATH}/:variant_id`;

export const BRAND_PATH = `${BASE_PATH}brand`;
export const ADD_BRAND_PATH = `${BASE_PATH}brand/add`;
export const VIEW_BRAND_PATH = `${BASE_PATH}brand/view`;
export const VIEW_BRAND_BY_ID_PATH = `${BASE_PATH}${VIEW_BRAND_PATH}/:brand_id`;
export const EDIT_BRAND_PATH = `${BASE_PATH}brand/edit`;
export const EDIT_BRAND_BY_ID_PATH = `${BASE_PATH}${EDIT_BRAND_PATH}/:brand_id`;

export const STAFF_PATH = `${BASE_PATH}staff`;
export const ADD_STAFF_PATH = `${BASE_PATH}staff/add`;
export const VIEW_STAFF_PATH = `${BASE_PATH}staff/view`;
export const VIEW_STAFF_BY_ID_PATH = `${BASE_PATH}${VIEW_STAFF_PATH}/:staff_id`
export const EDIT_STAFF_PATH = `${BASE_PATH}staff/edit`;
export const EDIT_STAFF_BY_ID_PATH = `${BASE_PATH}${EDIT_STAFF_PATH}/:staff_id`;

export const BRANCH_PATH = `${BASE_PATH}branch`;
export const ADD_BRANCH_PATH = `${BASE_PATH}branch/add`;
export const VIEW_BRANCH_PATH = `${BASE_PATH}branch/view`;
export const VIEW_BRANCH_BY_ID_PATH = `${BASE_PATH}${VIEW_BRANCH_PATH}/:branch_id`;
export const EDIT_BRANCH_PATH = `${BASE_PATH}branch/edit`;
export const EDIT_BRANCH_BY_ID_PATH = `${BASE_PATH}${EDIT_BRANCH_PATH}/:branch_id`;

export const SCREEN_PATH = `${BASE_PATH}screen`;
export const VIEW_SCREEN_PATH = `${BASE_PATH}screen/view`;
export const VIEW_SCREEN_BY_ID_PATH = `${BASE_PATH}${VIEW_SCREEN_PATH}/:screen_id`;

export const ITEM_PATH = `${BASE_PATH}item`;
export const ADD_ITEM_PATH = `${BASE_PATH}item/add`;
export const VIEW_ITEM_PATH = `${BASE_PATH}item/view`;
export const VIEW_ITEM_BY_ID_PATH = `${BASE_PATH}${VIEW_ITEM_PATH}/:item_id`;
export const EDIT_ITEM_PATH = `${BASE_PATH}item/edit`;
export const EDIT_ITEM_BY_ID_PATH = `${BASE_PATH}${EDIT_ITEM_PATH}/:item_id`;
export const MAP_CATEGORIES_PATH = `${BASE_PATH}item/map-categories`;
export const MAP_CATEGORIES_BY_ID_PATH = `${BASE_PATH}${MAP_CATEGORIES_PATH}/:item_id`;
export const MAP_MODELS_PATH = `${BASE_PATH}item/map-models`;
export const MAP_MODELS_BY_ID_PATH = `${BASE_PATH}${MAP_MODELS_PATH}/:item_id`;

export const UOM_PATH = `${BASE_PATH}uom`;
export const ADD_UOM_PATH = `${BASE_PATH}uom/add`;
export const VIEW_UOM_PATH = `${BASE_PATH}uom/view`;
export const VIEW_UOM_BY_ID_PATH = `${BASE_PATH}${VIEW_UOM_PATH}/:uom_id`;
export const EDIT_UOM_PATH = `${BASE_PATH}uom/edit`;
export const EDIT_UOM_BY_ID_PATH = `${BASE_PATH}${EDIT_UOM_PATH}/:uom_id`;

export const COURIER_PATH = `${BASE_PATH}courier`;
export const ADD_COURIER_PATH = `${BASE_PATH}courier/add`;
export const VIEW_COURIER_PATH = `${BASE_PATH}courier/view`;
export const VIEW_COURIER_BY_ID_PATH = `${BASE_PATH}${VIEW_COURIER_PATH}/:courier_id`;
export const EDIT_COURIER_PATH = `${BASE_PATH}courier/edit`;
export const EDIT_COURIER_BY_ID_PATH = `${BASE_PATH}${EDIT_COURIER_PATH}/:courier_id`;

export const DISCOUNT_PATH = `${BASE_PATH}discount`;
export const ADD_DISCOUNT_PATH = `${BASE_PATH}discount/add`;
export const EDIT_DISCOUNT_PATH = `${BASE_PATH}discount/edit`;
export const EDIT_DISCOUNT_BY_ID_PATH = `${BASE_PATH}${EDIT_DISCOUNT_PATH}/:discount_id`;
export const VIEW_DISCOUNT_PATH = `${BASE_PATH}discount/view`;
export const VIEW_DISCOUNT_BY_ID_PATH = `${BASE_PATH}${VIEW_DISCOUNT_PATH}/:discount_id`;

export const DISCOUNT_GROUP_PATH = `${BASE_PATH}discount-group`;
export const ADD_DISCOUNT_GROUP_PATH = `${BASE_PATH}discount-group/add`;
export const EDIT_DISCOUNT_GROUP_PATH = `${BASE_PATH}discount-group/edit`;
export const EDIT_DISCOUNT_GROUP_BY_ID_PATH = `${BASE_PATH}${EDIT_DISCOUNT_GROUP_PATH}/:discount_group_id`;
export const VIEW_DISCOUNT_GROUP_PATH = `${BASE_PATH}discount-group/view`;
export const VIEW_DISCOUNT_GROUP_BY_ID_PATH = `${BASE_PATH}${VIEW_DISCOUNT_GROUP_PATH}/:discount_group_id`;

export const DISCOUNT_STRUCTURE_PATH = `${BASE_PATH}discount-structure`;
export const ADD_DISCOUNT_STRUCTURE_PATH = `${BASE_PATH}discount-structure/add`;
export const EDIT_DISCOUNT_STRUCTURE_PATH = `${BASE_PATH}discount-structure/edit`;
export const EDIT_DISCOUNT_STRUCTURE_BY_ID_PATH = `${BASE_PATH}${EDIT_DISCOUNT_STRUCTURE_PATH}/:discount_structure_id`;
export const VIEW_DISCOUNT_STRUCTURE_PATH = `${BASE_PATH}discount-structure/view`;
export const VIEW_DISCOUNT_STRUCTURE_BY_ID_PATH = `${BASE_PATH}${VIEW_DISCOUNT_STRUCTURE_PATH}/:discount_structure_id`;


export const SCHEME_DISCOUNT_PATH = `${BASE_PATH}scheme-discount`;
export const ADD_SCHEME_DISCOUNT_PATH = `${BASE_PATH}scheme-discount/add`;
export const EDIT_SCHEME_DISCOUNT_PATH = `${BASE_PATH}scheme-discount/edit`;
export const EDIT_SCHEME_DISCOUNT_BY_ID_PATH = `${BASE_PATH}${EDIT_SCHEME_DISCOUNT_PATH}/:item_id`;
export const VIEW_SCHEME_DISCOUNT_PATH = `${BASE_PATH}scheme-discount/view`;
export const VIEW_SCHEME_DISCOUNT_BY_ID_PATH = `${BASE_PATH}${VIEW_SCHEME_DISCOUNT_PATH}/:item_id`;

export const COUPON_PATH = `${BASE_PATH}coupon`;
export const ADD_COUPON_PATH = `${BASE_PATH}coupon/add`;
export const EDIT_COUPON_PATH = `${BASE_PATH}coupon/edit`;
export const EDIT_COUPON_BY_ID_PATH = `${BASE_PATH}${EDIT_COUPON_PATH}/:coupon_id`;
export const VIEW_COUPON_PATH = `${BASE_PATH}coupon/view`;
export const VIEW_COUPON_BY_ID_PATH = `${BASE_PATH}${VIEW_COUPON_PATH}/:coupon_id`;

export const ORDERS_PATH = `${BASE_PATH}orders`;
export const VIEW_ORDER_PATH = `${BASE_PATH}orders/view`;
export const VIEW_ORDER_BY_ID_PATH = `${BASE_PATH}${VIEW_ORDER_PATH}/:customer_order_id`;

export const DEFAULT_DISCOUNT = `${BASE_PATH}default-discount`;
export const VIEW_DEFAULT_DISCOUNT = `${BASE_PATH}default-discount/view`;
export const VIEW_DEFAULT_DISCOUNT_BY_ID_PATH = `${BASE_PATH}${VIEW_DEFAULT_DISCOUNT}/:default_discount_id`;

export const UNKNOWN_PATH = `*`;