import { SET_LOGGED_IN_USER, REMOVE_LOGGED_IN_USER, SET_FORGOT_USER } from '../constant';

export const loginAction = (user) => {
    return {
        type: SET_LOGGED_IN_USER,
        info: "user login and store user details in store",
        payload: user,
    };
};

export const logoutAction = () => {
    return {
        type: REMOVE_LOGGED_IN_USER,
        info: "user logout",
        payload:{
            isLoggedIn: false,
            token: '',
            authUser: '',
        }
    };
};

export const forgotAction = (user) => dispatch => {
    dispatch({
        type: SET_FORGOT_USER,
        information: 'user forgot data',
        payload: user,
    });
};