import React, { lazy, Suspense, useEffect, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import { loginAction } from "redux/action";
import { localStorage, utils } from "core/helper";
import { LOGIN_PATH } from '../routes/routes';

const PreLogin = lazy(() => import("../preLogin/preLogin"));
const PostLogin = lazy(() => import("../postLogin/postLogin"));

const Container = (props) => {
    const { loginAction } = props;
    let navigate = useNavigate();
    const token = localStorage.getAuthToken();

    useEffect(() => {
        // checkSessionlogin();
        if (token !== "") {
            const authUser = localStorage.getAuthUser();
            loginAction({ isLoggedIn: true, authUser, token });
        } else {
            console.log('token not there');
            utils.navigateTo(navigate, LOGIN_PATH);
        }
    }, []);

    return (
        <Fragment>
            <Suspense fallback={
                <div id="global-loader">
                    <img src="/assets/images/svgs/loader.svg" className="loader-img" alt="Loader" />
                </div>
            }>
                {token && <PostLogin />}
                {!token && <PreLogin />}
            </Suspense>
            <ToastContainer
                draggable={false}
                transition={Zoom}
                theme="dark"
            />
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.account?.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginAction: (user) => dispatch(loginAction(user))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);