
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from 'redux/store';
import Container from './container';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Container />
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default App;
